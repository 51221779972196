import Vue from 'vue'
import VueRouter from 'vue-router'
import Navigation100 from '../components/Navigation100.vue'
import Navigation200 from '../components/Navigation200.vue'
import Navigation300 from '../components/Navigation300.vue'
import Navigation500 from '../components/Navigation500.vue'
import Navigation600 from '../components/Navigation600.vue'
import login from '../views/login.vue'

Vue.use(VueRouter)



const routes = [
  {
    path: '/index',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '',
        name: 'Index',
        component: () => import('@/views/index.vue')
      },
    ]
  },
  {
    path: '/',
    name: 'Login',
    component: login
  },
  
  {
    path: '/login',
    component: () => import('@/layout/login.vue'),
    children: [
      {
        path: 'id_lost',
        name: 'IdLost',
        component: () => import('@/views/login/id_lost.vue')
      },
      {
        path: 'password_lost',
        name: 'PasswordLost',
        component: () => import('@/views/login/password_lost.vue')
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/login/register.vue')
      },
    ]
  },
  {
    path: '/Navigation100',
    name: 'Navigation100',
    component: Navigation100
  },
  {
    path: '/Navigation200',
    name: 'Navigation200',
    component: Navigation200
  },
  {
    path: '/Navigation300',
    name: 'Navigation300',
    component: Navigation300
  },
  {
    path: '/Navigation500',
    name: 'Navigation500',
    component: Navigation500
  },
  {
    path: '/Navigation600',
    name: 'Navigation600',
    component: Navigation600
  },
  {
    path: '/attend',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '',
        name: 'AttendIndex',
        component: () => import('@/views/attend/index.vue')
      },
      {
        path: 'pickup',
        name: 'AttendPickup',
        component: () => import('@/views/attend/pickup.vue')
      },
      {
        path: 'notice',
        name: 'AttendNotice',
        component: () => import('@/views/attend/notice.vue')
      },
      {
        path: 'statistics',
        name: 'AttendStatistics',
        component: () => import('@/views/attend/statistics.vue')
      },
      {
        path: 'index_back',
        name: 'AttendIndexBack',
        component: () => import('@/views/attend/index_back.vue')
      },
    ]
  },
  {
    path: '/life',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '',
        name: 'LifeIndex',
        component: () => import('@/views/life/index.vue')
      },
      {
        path: 'attention',
        name: 'LifeAttention',
        component: () => import('@/views/life/attention.vue')
      },
      {
        path: 'neutered',
        name: 'LifeMeutered',
        component: () => import('@/views/life/neutered.vue')
      },
      {
        path: 'meal',
        name: 'LifeMeal',
        component: () => import('@/views/life/meal.vue')
      },
      {
        path: 'walk',
        name: 'LifeWalk',
        component: () => import('@/views/life/walk.vue')
      },
      {
        path: 'dosage',
        name: 'LifeDosage',
        component: () => import('@/views/life/dosage.vue')
      },
      {
        path: 'notice',
        name: 'LifeNotice',
        component: () => import('@/views/life/notice.vue')
      },
    ]
  },
  {
    path: '/student',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '',
        name: 'GuardianList',
        component: () => import('@/views/student/index.vue')
      },
      {
        path: 'guardian_view',
        name: 'GuardianView',
        component: () => import('@/views/student/guardian_view.vue')
      },
      {
        path: 'guardian_addition',
        name: 'GuardianAddition',
        component: () => import('@/views/student/guardian_addition.vue')
      },
      {
        path: 'student',
        name: 'StudentList',
        component: () => import('@/views/student/student.vue')
      },
      {
        path: 'student_view',
        name: 'StudentView',
        component: () => import('@/views/student/student_view.vue')
      },
      {
        path: 'student_addition',
        name: 'StudentAddition',
        component: () => import('@/views/student/student_addition.vue')
      },
    ]
  },
  {
    path: '/schedule',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '',
        name: 'ScheduleIndex',
        component: () => import('@/views/schedule/index.vue')
      },
    ]
  },
  {
    path: '/pay',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '',
        name: 'PayIndex',
        component: () => import('@/views/pay/index.vue')
      },
      {
        path: 'history',
        name: 'PayHistory',
        component: () => import('@/views/pay/history.vue')
      },
      {
        path: 'non_payment',
        name: 'NonPayment',
        component: () => import('@/views/pay/non_payment.vue')
      },
      {
        path: 'statistics',
        name: 'PayStatistics',
        component: () => import('@/views/pay/statistics.vue')
      },
    ]
  },
  {
    path: '/integrated',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '',
        name: 'IntegratedIndex',
        component: () => import('@/views/integrated/index.vue')
      },
      {
        path: 'gateway',
        name: 'IntegratedGateway',
        component: () => import('@/views/integrated/gateway.vue')
      },
      {
        path: 'device',
        name: 'IntegratedDevice',
        component: () => import('@/views/integrated/device.vue')
      },
      {
        path: 'vehicle',
        name: 'IntegratedVehicle',
        component: () => import('@/views/integrated/vehicle.vue')
      },
    ]
  },
  {
    path: '/community',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '',
        name: 'CommunityIndex',
        component: () => import('@/views/community/index.vue')
      },
      {
        path: 'faq',
        name: 'CommunityFAQ',
        component: () => import('@/views/community/faq.vue')
      },
      {
        path: 'manual',
        name: 'CommunityManual',
        component: () => import('@/views/community/manual.vue')
      },
      {
        path: 'inquiry',
        name: 'CommunityInquiry',
        component: () => import('@/views/community/inquiry.vue')
      },
    ]
  },
  {
    path: '/setting',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '',
        name: 'SettingIndex',
        component: () => import('@/views/setting/index.vue')
      },
      {
        path: 'notice',
        name: 'SettingNotice',
        component: () => import('@/views/setting/notice.vue')
      },
      {
        path: 'passward_update',
        name: 'SettingPasswardUpdate',
        component: () => import('@/views/setting/passward_update.vue')
      },
      {
        path: 'email_update',
        name: 'SettingEmailUpdate',
        component: () => import('@/views/setting/email_update.vue')
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!Vue.$cookies.get("accessToken");
  if (to.name !== 'Login' && !isAuthenticated) {
    alert('로그인 후 이용해주세요.')
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router
