<template>
    <div class="navigation__wrap menu100">
        <ul class="df">
            <li><router-link to="/attend/" exact-active-class="active">출결관리 ({{ attend }})</router-link></li>
            <li><router-link to="/attend/pickup" exact-active-class="active">픽업관리 ({{ pickup }})</router-link></li>
            <li><router-link to="/attend/notice" exact-active-class="active">견주전달사항 ({{ message }})</router-link></li>
            <li><router-link to="/attend/statistics" exact-active-class="active">통계</router-link></li>
        </ul>
    </div>
</template>

<script>
import axios from "axios";
// @ is an alias to /src

export default {
    name: 'Attend',
    components: {
    },
    data() {
        return {
            attend: '',
            pickup: '',
            message: '',
        }
    },
    mounted() {
        axios.get('https://api.peachbite.co.kr/kinder/attend/list_page', {
            headers: {
                'Authorization': `${this.$cookies.get("token_type")} ${this.$cookies.get("accessToken")}`,
                'Accept': 'application/json',
            },
        })
        .then(response => {
        this.attend = response.data.attend;
        this.pickup = response.data.pickup;
        this.message = response.data.message;
        })
        .catch(error => {
        console.log(error);
        });
    }
}
</script>