<template>
    <div class="navigation__wrap menu300">
        <ul class="df">
            <li><router-link to="/student/" exact-active-class="active">보호자 관리 ()</router-link></li>
            <li><router-link to="/student/student" exact-active-class="active">원생 관리 ()</router-link></li>
        </ul>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Student',
    components: {
    }
}
</script>