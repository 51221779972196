<template>
  <div class="container__wrap login__wrap">
    <div class="login df">
      <div class="login__background"><img src="~@/assets/images/login-background.png" alt="이미지"></div>
      <div class="login__side df">
        <div class="content__wrap">
          <div class="logo__box">
            <svg xmlns="https://www.w3.org/2000/svg" width="118.464" height="104.726" viewBox="0 0 118.464 104.726">
              <path id="패스_36" data-name="패스 36" d="M93.83,14.935a15.083,15.083,0,0,0,2.8,1.915,10.014,10.014,0,0,0,1.213.53h0s5.492,2.5,12.2-1.908l.019-.013a11.084,11.084,0,0,0,4.061-4.247,2.523,2.523,0,0,0,.184-1.988,14.3,14.3,0,0,0-6.441-8.19,8.11,8.11,0,0,0-2.729-.888c-5.05-.755-9.606,1.888-14.333,3.141a16.57,16.57,0,0,1-4.219.689,4.03,4.03,0,0,1-.89-.066.1.1,0,0,0-.112.152,49.655,49.655,0,0,0,8.228,10.88Z" transform="translate(-45.09 -0.012)" fill="#fff"/>
              <path id="패스_37" data-name="패스 37" d="M117.424,75.3A35.709,35.709,0,0,0,121.2,59.15a.1.1,0,0,0-.171-.08c-.752.709-4.015,3.253-10.614,1.849a12.948,12.948,0,0,1-5.182-2.849A12.212,12.212,0,0,1,101.1,47.8c.191-3.32,1.279-6.752,3.8-9.025a9.694,9.694,0,0,1,1.246-.954.1.1,0,0,0-.02-.186A19.5,19.5,0,0,0,103.679,37a21.983,21.983,0,0,0-4.47-.431,17.637,17.637,0,0,0-5.472.868,14.8,14.8,0,0,0-5.465,3.26A12.877,12.877,0,0,0,85.7,44.348a27.021,27.021,0,0,0-1.78,4.565,40.179,40.179,0,0,0-1.338,6.9c-.033.3-.066.61-.092.914a.06.06,0,0,1-.119.013c-.4-1.769-1.866-9.667,1.859-15.949a.155.155,0,0,0-.046-.205c-1.187-.716-10.575-5.97-20.141.921A17.5,17.5,0,0,0,60.582,45,24.583,24.583,0,0,0,55.809,55.87,28.65,28.65,0,0,0,58.387,73.94a39.09,39.09,0,0,0,5.1,7.3A51.876,51.876,0,0,0,73.82,90.174,69.008,69.008,0,0,0,88.4,97.449a.981.981,0,0,0,.725-.02,106.163,106.163,0,0,0,14.326-7.64c5.828-3.717,10.819-8.263,13.97-14.485Z" transform="translate(-29.168 -19.274)" fill="#fff"/>
              <path id="패스_38" data-name="패스 38" d="M5.808,197.348H3.165v6.083H0v-15.85H5.808c3.8,0,5.7,2.16,5.7,4.9,0,2.405-1.628,4.857-5.7,4.857Zm-.138-2.564c1.787,0,2.578-.888,2.578-2.293s-.791-2.312-2.578-2.312H3.165v4.612H5.67Z" transform="translate(0 -98.844)" fill="#fff"/>
              <path id="패스_39" data-name="패스 39" d="M38.178,190.137H32.192v3.976H37.5v2.518H32.192v4.2h5.986v2.59H29.027V187.553h9.151v2.584Z" transform="translate(-15.295 -98.829)" fill="#fff"/>
              <path id="패스_40" data-name="패스 40" d="M63.017,200.4H56.734l-1.042,3.021H52.369l5.67-15.869h3.685l5.67,15.869H64.052L63.01,200.4Zm-3.138-9.171L57.6,197.864h4.562Z" transform="translate(-27.594 -98.829)" fill="#fff"/>
              <path id="패스_41" data-name="패스 41" d="M95,187.161a7.432,7.432,0,0,1,7.41,5.129H98.767a3.974,3.974,0,0,0-3.8-2.273c-2.756,0-4.721,2.021-4.721,5.248s1.965,5.248,4.721,5.248a3.959,3.959,0,0,0,3.8-2.293h3.639A7.408,7.408,0,0,1,95,203.349a8.094,8.094,0,0,1,0-16.187Z" transform="translate(-45.841 -98.623)" fill="#fff"/>
              <path id="패스_42" data-name="패스 42" d="M135.2,196.7h-6.758v6.745h-3.165V187.6h3.165v6.513H135.2V187.6h3.165v15.849H135.2Z" transform="translate(-66.011 -98.852)" fill="#fff"/>
              <path id="패스_43" data-name="패스 43" d="M166.33,203.431h-6.8v-15.85h6.507c3.3,0,5.129,1.723,5.129,4.088a3.65,3.65,0,0,1-2.69,3.631,3.838,3.838,0,0,1,3.052,3.836c0,2.518-1.918,4.294-5.2,4.294Zm-.745-13.285h-2.894v3.976h2.894c1.49,0,2.347-.682,2.347-1.974S167.075,190.146,165.585,190.146Zm.224,6.427h-3.118v4.267h3.184c1.536,0,2.439-.749,2.439-2.087S167.345,196.573,165.809,196.573Z" transform="translate(-84.06 -98.844)" fill="#fff"/>
              <path id="패스_44" data-name="패스 44" d="M190.232,187.6H193.4v15.849h-3.165Z" transform="translate(-100.235 -98.852)" fill="#fff"/>
              <path id="패스_45" data-name="패스 45" d="M201.743,187.6h11.564v2.564h-4.2v13.285h-3.164V190.16h-4.2Z" transform="translate(-106.3 -98.852)" fill="#fff"/>
              <path id="패스_46" data-name="패스 46" d="M240.213,190.137h-5.986v3.976h5.307v2.518h-5.307v4.2h5.986v2.59h-9.151V187.553h9.151v2.584Z" transform="translate(-121.749 -98.829)" fill="#fff"/>
            </svg>
          </div>
          <div class="title__box">
            <h1>피치바잇 펫케어솔루션</h1>
            <ul class="text__list df">
                <li class="text">유치원</li>
                <li class="text">호텔</li>
                <li class="text">카페</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="login__content">
        <div class="login__box">
          <div class="login-title">Administrator Log In</div>
          <div class="login-input">
              <ul class="input-list">
                <li><el-input placeholder="ID" v-model="username " class="login__type"></el-input></li>
                <li><el-input placeholder="PW" v-model="password" class="login__type" show-password></el-input></li>
                <li class="button__li"><button type="submit" @click="login" class="btn btn-submit">로그인</button></li>
              </ul>
              <div class="login-input__line df">
              <div class="line__block">
                <el-checkbox v-model="mbAutoLogin">자동로그인</el-checkbox>
              </div>
              <div class="line__block">
                <el-checkbox v-model="mbRememberId">아이디 저장</el-checkbox>
              </div>
              </div>
          </div>
          <div class="login-find df">
              <div class="login-find__inline"><router-link to="/login/register" class="link_login">가입 신청</router-link></div>
              <div class="login-find__inline"><router-link to="/login/id_lost" class="link_login">아이디 찾기</router-link></div>
              <div class="login-find__inline last"><router-link to="/login/password_lost" class="link_login">비밀번호찾기</router-link></div>
          </div>
          <div class="login-copy">CORYRIGHT 2023 MAXKKOREA INC. all rights reserved.</div>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import axios from 'axios';
  export default {
    name: 'Login',
    components: {
    },
    data() {
      return {
        username : '',
        password: '',
        mbAutoLogin: false,
        mbRememberId: ''
      }
    },
    methods: {
      login: function() {
        // 사용자 이름과 비밀번호를 가져온다.
        var username = this.username;
        var password = this.password;
        
        // 로그인 버튼을 클릭한다.
        document.getElementById("loginButton").click();
      },
      initUser() {
        this.$cookies.remove('accessToken')
        this.$cookies.remove('token_type')
        this.$cookies.remove('expire_Date')
      },
      async login() {
        try {
          const keepLogin = this.mbAutoLogin ? 1 : 0;
          const saveUserID = this.mbRememberId ? 1 : 0;
          const response = await axios.post('https://api.peachbite.co.kr/users/login', null, {
            params: {
              username: this.username,
              password: this.password,
              keepLogin: keepLogin,
            },
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': 'application/json',
            },
          });
          const accessToken = response.data.access_token;
          const tokenType = response.data.token_type;
          const expireDate = response.data.expire_date;

          this.$cookies.set('accessToken', accessToken);
          this.$cookies.set('token_type', tokenType);
          this.$cookies.set('expire_Date', expireDate);
          this.$cookies.set('keepLogin', keepLogin);
          this.$cookies.set('saveUserID', saveUserID);

          this.saveAuthentication()
        } catch (error) {
          this.messageOpen("로그인 정보가 일치하지 않습니다.","피치바잇","Login");
        }
      },
      async saveAuthentication() {
        try {
          const response = await axios.get('https://api.peachbite.co.kr/users/authcheck/', {
            headers: {
              'Authorization': `${this.$cookies.get("token_type")} ${this.$cookies.get("accessToken")}`,
              'Accept': 'application/json',
            },
            params:{
              'keepLogin':this.$cookies.get('keepLogin')
            }
          });

          const accessToken = response.data.access_token;
          const token_type = response.data.token_type; 
          const expireDate = response.data.expire_Date; 
          const user_id = response.data.user_id;
          const user_type = response.data.user_type;

          this.$cookies.set('accessToken', accessToken);
          this.$cookies.set('token_type', token_type);
          this.$cookies.set('expire_Date', expireDate);
          this.$cookies.set('user_id', user_id);
          this.$cookies.set('user_type', user_type);
          this.$cookies.set('keepLogin', this.$cookies.get('keepLogin'));
          this.$cookies.set('saveUserId', this.$cookies.get('saveUserId'));

          if(user_type != "kinder"){
            this.messageOpen("유치원 관리자만 로그인 가능합니다.","피치바잇","Login")
          }
          else {
            this.$router.push({ name: "Index" });
          }
        } catch (error) {
          this.messageOpen("로그인 유효하지 않습니다.","피치바잇","Login");
        }
      },
      messageOpen(message,title,target) {
        this.$alert(message, title, {
          confirmButtonText: '확인',
          function(){
            this.$router.push({ name: target });
          }
        });
      }
    },
    mounted: function() {
      this.initUser();
      // 로그인 입력창에서 엔터를 누르면 login() 함수를 호출한다.
      document.addEventListener("keydown", function(event) {
        if (event.key === "Enter") {
          event.preventDefault();
          this.login();
        }
      }.bind(this));
    }
  }
  </script>
  