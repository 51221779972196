import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueApexCharts from 'vue-apexcharts'
import locale from 'element-ui/lib/locale/lang/ko';
import vueCookies from "vue-cookies";

import('./assets/css/common.css')
import('./assets/css/default.css')
import('./assets/css/header.css')

Vue.use(ElementUI, { locale });
Vue.config.productionTip = false
Vue.use(VueApexCharts)
Vue.use(vueCookies);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// 아래에 주어진 코드를 추가
Vue.mixin({
  beforeRouteEnter(to, from, next) {
    if (to.name !== 'LoginIndex' && to.name !== undefined) {
      // 로그인 페이지가 아닌 경우에만 세션 만료 여부 검사
      const expireDate = new Date(decodeURIComponent(Vue.$cookies.get('expire_date')));
      const currentDate = new Date(); // 현재 시간
      const expireDateTime = new Date(expireDate); // 세션 만료 시간

      if (currentDate > expireDateTime) {
        // 세션이 만료된 경우
        alert('로그인 세션이 만료되어 로그아웃 됩니다.')
        next({ name: 'LoginIndex' }); // 로그인 페이지로 이동
      } else {
        // 세션이 유효한 경우
        next();
      }
    } else {
      // 로그인 페이지인 경우에는 예외 처리
      next();
    }
  }
});